import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VCard,_vm._g(_vm._b({staticClass:"pa-2 d-flex flex-column",attrs:{"outlined":""}},'v-card',attrs,false),on),[_c('small',{staticClass:"text--disabled"},[_vm._v("Target Prospek")]),_c('div',{staticClass:"mt-2"},[_c(VProgressLinear,{attrs:{"value":30,"height":"12","rounded":""}},[_c('small',[_vm._v("30 %")])])],1)])]}}])},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Target: 123123")]),_c('span',[_vm._v("Progres: 123123")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }