<template>
  <div>
    <v-tooltip
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card
          class="pa-2 d-flex flex-column"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <small class="text--disabled">Target Prospek</small>
          <div class="mt-2">
            <v-progress-linear
              :value="30"
              height="12"
              rounded
            >
              <small>30 %</small>
            </v-progress-linear>
          </div>
        </v-card>
      </template>
      <div class="d-flex flex-column">
        <span>Target: 123123</span>
        <span>Progres: 123123</span>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
