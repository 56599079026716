<template>
  <div id="user-view">
    <app-breadcrumb
      title="Detail Pengguna"
      hide-breadcrumbs
    >
      <template #action>
        <v-btn
          outlined
          small
          color="error"
        >
          Hapus Pengguna
        </v-btn>
      </template>
    </app-breadcrumb>
    <v-row v-if="detail.first_name">
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-card class="pa-4 mb-4">
          <div class="d-flex flex-column align-center">
            <v-avatar
              size="80"
              class="v-avatar-light-bg mb-2"
            >
              {{ avatarText(`${detail.first_name} ${detail.last_name}`) }}
            </v-avatar>
            <span class="text-subtitle-2 font-weight-bold primary--text text-center">{{ detail.first_name }} {{ detail.last_name }}</span>
            <span class="text-subtitle-2 text-center">{{ detail.email }}</span>
            <span class="text-subtitle-2 text-center">{{ detail.phone }}</span>
            <div class="d-flex mt-2 mb-4">
              <v-chip
                small
                color="primary"
                class="mx-1 v-chip-light-bg primary--text"
              >
                {{ detail.role.name }}
              </v-chip>
              <v-chip
                small
                class="mx-1"
                :color="userStatusColor(detail.user_status.id)"
              >
                {{ detail.user_status.name }}
              </v-chip>
            </div>
            <v-card
              outlined
              class="pa-3 d-flex justify-space-between w-100 align-center"
            >
              <span class="text-subtitle-2">Ketersediaan</span>
              <v-switch
                :value="detail.availability"
                disabled
                hide-details
                dense
                class="ma-0"
              />
            </v-card>
          </div>
        </v-card>
        <v-card class="pa-4 mb-4">
          <span class="text-subtitle-2 text--disabled">Hierarki</span>
          <v-card
            outlined
            class="pa-3 mt-2 d-flex justify-space-between"
            @click.stop
          >
            <span class="text-subtitle-2 primary--text">Hierarki A</span>
            <v-icon
              color="primary"
              size="20"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-card>
        </v-card>
        <v-card class="pa-4 mb-4">
          <span class="text-subtitle-2 text--disabled">Tim</span>
          <v-card
            v-for="i in 3"
            :key="i"
            outlined
            class="pa-3 mt-2 d-flex justify-space-between"
            @click.stop
          >
            <span class="text-subtitle-2 primary--text">Tim {{ i }}</span>
            <v-icon
              color="primary"
              size="20"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-card>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-tabs
          v-model="currentTab"
          class="user-tabs mb-4"
          show-arrows
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.label"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span class="text-capitalize text-subtitle-2">{{ tab.label }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="currentTab"
          touchless
        >
          <v-tab-item>
            <user-performance />
          </v-tab-item>
          <v-tab-item>
            <user-prospect />
          </v-tab-item>
          <v-tab-item>
            <user-job />
          </v-tab-item>
          <v-tab-item>
            <user-task />
          </v-tab-item>
          <v-tab-item>
            <v-card class="mb-4">
              <v-card-title class="font-weight-bold">
                Objek Dipantau
              </v-card-title>
              <v-card-text>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad eius hic eaque ratione! Amet libero ea nostrum reprehenderit mollitia veniam adipisci inventore aliquid laboriosam officiis expedita, sed rerum, doloribus minus.
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mb-4">
              <v-card-title class="font-weight-bold">
                Pengaturan Akun
              </v-card-title>
              <v-card-text>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad eius hic eaque ratione! Amet libero ea nostrum reprehenderit mollitia veniam adipisci inventore aliquid laboriosam officiis expedita, sed rerum, doloribus minus.
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <user-target-form
      ref="userTargetForm"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  mdiInformation, mdiChartAreaspline, mdiTagHeart, mdiTicketAccount,
  mdiCalendarCheck, mdiAccountCog, mdiEye, mdiOpenInNew,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import UserTargetForm from './UserTargetForm.vue'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import router from '@/router'
import useUser from '@/composables/useUser'
import UserProspect from './components/UserProspect.vue'
import UserPerformance from './components/UserPerformance.vue'
import UserJob from './components/UserJob.vue'
import UserTask from './components/UserTask.vue'
import { can } from '@/@core/libs/acl/utils'

export default {
  components: {
    AppBreadcrumb,
    UserTargetForm,
    UserProspect,
    UserPerformance,
    UserJob,
    UserTask,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const loadingDetail = ref(false)
    const detail = ref({})
    const currentTab = ref(0)
    const tabs = ref([
      {
        label: 'Performa',
        icon: mdiChartAreaspline,
      },
      {
        label: 'Prospek',
        icon: mdiTagHeart,
      },
      {
        label: 'Job',
        icon: mdiTicketAccount,
      },
      {
        label: 'Task',
        icon: mdiCalendarCheck,
      },
      {
        label: 'Dipantau',
        icon: mdiEye,
      },
      {
        label: 'Pengaturan',
        icon: mdiAccountCog,
      },
    ])

    const findUser = () => {
      loadingDetail.value = true

      const user = store.state.workspace.selectedWorkspace.workspace.members.find(el => el.user.id === props.id)

      if (user) {
        detail.value = {
          ...user.user,
          role: user.role,
        }

        console.log(user)
      } else {
        router.push('/error')
        errorHandling('Pengguna tidak ditemukan')
      }

      loadingDetail.value = false
    }

    onMounted(() => {
      if (can('read', 'User')) {
        findUser()
      } else {
        router.push('/error')
        errorHandling('Anda tidak memiliki akses')
      }
    })

    const { userStatusColor } = useUser()

    return {
      loadingDetail,
      detail,
      tabs,
      avatarText,
      currentTab,
      userStatusColor,

      icons: {
        mdiInformation,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
