<template>
  <v-card>
    <div class="pa-3 pb-0 d-flex justify-space-between align-center">
      <h3>Target</h3>
      <v-btn
        color="primary"
        small
      >
        Set Target
      </v-btn>
    </div>
    <PerfectScrollbar
      :options="{useBothWheelAxes:true}"
      class="target-list pa-3"
    >
      <user-target-card
        v-for="i in 5"
        :key="i"
        class="target-list-card"
      />
    </PerfectScrollbar>
    <div class="pa-3">
      <v-card
        v-for="i in 3"
        :key="i"
        outlined
        height="40vh"
        class="d-flex justify-center align-center mb-3"
      >
        Performance Goes Here
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import UserTargetCard from '../UserTargetCard.vue'

export default {
  components: {
    UserTargetCard,
    PerfectScrollbar,
  },
}
</script>

<style lang="scss" scoped>
.target-list {
  display: flex;
  overflow-x: auto;

  .target-list-card {
    flex-shrink: 0;
    width: 240px;
    margin-right: 12px;
  }
}
</style>
