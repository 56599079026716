import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{attrs:{"height":"60vh"}},[_c('div',{staticClass:"d-flex pa-4"},[_c('h4',[_vm._v("Set Target")]),_c(VIcon,{staticClass:"ml-auto",on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VDivider),_c('div',{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VList,{staticClass:"pa-0",attrs:{"nav":""}},_vm._l((_vm.object),function(item){return _c(VListItem,{key:item.name,staticClass:"d-flex target-object mb-2",on:{"click":function($event){$event.stopPropagation();}}},[_c(VIcon,{staticClass:"mr-4"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1)],1),_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VCard,{staticClass:"pa-3",attrs:{"outlined":""}},[_vm._v(" hola ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }