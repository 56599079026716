<template>
  <v-card>
    <div class="pa-2">
      <v-text-field
        placeholder="Cari task..."
        hide-details
        solo
        flat
        dense
        :prepend-inner-icon="icons.mdiMagnify"
      />
    </div>
    <v-divider />
    <div class="scroll-list px-3 pt-2">
      <v-card
        v-for="i in 100"
        :key="i"
        class="pa-3 mb-2 mt-2"
        outlined
      >
        coba Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat cumque aspernatur, minus quaerat quasi recusandae ex aut est qui laborum, aperiam, quidem in asperiores facere. Nulla modi ipsam soluta assumenda?
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.scroll-list {
  @include style-scroll-bar();
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
